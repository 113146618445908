import React from "react";
import Header from "./common/Header";
import { Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import GalleryPage from "./GalleryPage";
import BelovedPage from "./BelovedPage";
import BioPage from "./BioPage";
import FaqPage from "./FaqPage";
import Footer from "./common/Footer";
import UnknownPage from "./UnknownPage";
import ContactPage from "./ContactPage";
import CommissionPage from "./CommissionPage";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400&display=swap');

body {
  font-family: "Raleway";
  background: #F2EDE4;
}

html { 
  overflow-y: scroll; 
}

.thumbnail{
	border: 0;
  background: inherit;
}
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/gallery/:media" exact component={GalleryPage} />
        <Route path="/beloved" component={BelovedPage} />
        <Route path="/bio" component={BioPage} />
        <Route path="/faq" component={FaqPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/commission" component={CommissionPage} />
        <Route path="/" component={UnknownPage} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
