import React from "react";
import { Link } from "react-router-dom";
import { Portfolio } from "../ui/HomePageStyles";

//gallery headers
import acrylic_header from "../img/gallery_headers/acrylic.png";
import commissions_header from "../img/gallery_headers/commissions.png";
// import gouache_header from "../img/gallery_headers/gouache.jpg";
import pen_ink_header from "../img/gallery_headers/pen_ink.jpg";
import public_art_header from "../img/gallery_headers/public_art.png";
import watercolor_header from "../img/gallery_headers/watercolor.png";
//import sketchbook_header from "../img/gallery_headers/sketchbook.jpg";
import digital_header from "../img/gallery_headers/digital.png";

function HomePage() {
  return (
    <Portfolio className="container">
      <div className="row row-centered">
        <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/acrylic">
              <div
                className="image"
                style={{ backgroundImage: "url(" + acrylic_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">acrylic</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/watercolor">
              <div
                className="image"
                style={{ backgroundImage: "url(" + watercolor_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">watercolor</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/pen_ink">
              <div
                className="image"
                style={{ backgroundImage: "url(" + pen_ink_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">pen + ink</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/public_art">
              <div
                className="image"
                style={{ backgroundImage: "url(" + public_art_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">public art</p>
          </div>
        </div>
        {/* <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/gouache">
              <div
                className="image"
                style={{ backgroundImage: "url(" + gouache_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">gouache</p>
          </div>
        </div> */}
        <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/commissions">
              <div
                className="image"
                style={{ backgroundImage: "url(" + commissions_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">commissions</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/digital">
              <div
                className="image"
                style={{ backgroundImage: "url(" + digital_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">digital</p>
          </div>
        </div>
        {/* <div className="col-md-4 col-sm-6 col-xs-12 col-centered">
          <div className="thumbnail gallery-link">
            <Link to="gallery/sketchbook">
              <div
                className="image"
                style={{ backgroundImage: "url(" + sketchbook_header + ")" }}
              />
            </Link>
            <p className="gallery-link-label">Sketchbook</p>
          </div>
        </div> */}
      </div>
    </Portfolio>
  );
}

export default HomePage;
