import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Logo, NavBar } from "../../ui/HeaderStyles";
import logo from "../../img/logo/Logo.png";
import * as JQuery from "jquery";
const $ = JQuery.default;

function Header() {
  useEffect(() => {
    $("ul.nav li.dropdown").hover(
      function() {
        $(this)
          .find(".dropdown-menu")
          .stop(true, true)
          .delay(10)
          .fadeIn(100);
      },
      function() {
        $(this)
          .find(".dropdown-menu")
          .stop(true, true)
          .delay(10)
          .fadeOut(100);
      }
    );
  }, []);

  return (
    <>
      <Logo className="container">
        <div className="row">
          <div className="col-md-4 col-xs-2" />
          <div className="col-md-4 col-xs-8">
            <Link to="/">
              <img className="logo-img" src={logo} alt="" />
            </Link>
            {/* <p className="logo-header">Original Artwork by Kendel Leslie</p> */}
            <hr className="logo-separator" />
          </div>
          <div className="col-md-4 col-xs-2" />
        </div>
      </Logo>
      <NavBar className="navbar-default">
        <div className="container">
          <div className="navbar-links row">
            <ul className="nav navbar-nav">
              <li>
                <Link to="/" className="nav-link">home</Link>
              </li>
              {/* <li className="dropdown col-xs-3">
                <a
                  href=" "
                  onClick={e => e.preventDefault()}
                  className="dropdown-toggle nav-link"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  about
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-link" to="/faq">
                      faq
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-link" to="/bio">
                      bio
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to="/beloved" className="nav-link">beloved</Link>
              </li>
              <li>
                <Link to="/bio" className="nav-link">bio</Link>
              </li>
              <li>
                <Link to="/contact" className="nav-link">contact</Link>
              </li>
              <li>
                <a href="https://www.etsy.com/shop/lupineravenartworks/" className="nav-link" target="_blank" rel="noopener noreferrer">shop</a>
              </li>
              <li>
                <Link to="/commission" className="nav-link">commissions</Link>
              </li>
              <li>
                <Link to="/faq" className="nav-link">faq</Link>
              </li>
              {/* <li className="dropdown col-xs-3">
                <a
                  href=" "
                  onClick={e => e.preventDefault()}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Shop
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-link" to="/commission">
                      Commission
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-link"
                      href="https://www.etsy.com/shop/artbykendel/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Shop
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-link"
                      href="https://www.etsy.com/shop/ArtByKendel?ref=shop_sugg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Etsy
                    </a>
                  </li> 
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
      </NavBar>
    </>
  );
}

export default Header;
