import React from "react";
import { About } from "../ui/CommonStyles";
import bioImage1 from "../img/about/BioImage1.jpg";
import bioImage2 from "../img/about/BioImage2.jpg";

function BioPage() {
  return (
    <About className="container">
      <div className="row">
        <div className="col-md-4 col-sm-5 col-xs-12">
          {/* TODO: add scroll through image viewer */}
          <div className="thumbnail">
            {/* <div
                className="image pic1"
                style={{ backgroundImage: "url(" + bioImage1 + ")" }}
              /> */}
            <img src={bioImage1} alt="kendel at show" className="image-bio" />
          </div>
        </div>
        <div className="col-md-4 col-sm-7 col-xs-12">
          <h1>about me</h1>
          <p className="bio-text">
            Hey there! My name is Kendel and I am the artist behind Lupine Raven
            Artworks (previously Art by Kendel).
          </p>
          <p className="bio-text">
            I started making art in high school around 2011 and pretty much just
            never stopped. I'm self taught and absolutely love painting animals,
            plants, landscapes, and really anything nature-y. My favorite
            mediums are watercolor, digital (Procreate on my iPad), and acrylic,
            but I work in many other mediums as well and am always learning and
            experimenting with new ideas.
          </p>
          <p className="bio-text">
            I have a huge heart for animals big and small, especially for the
            ones that are misunderstood. I have a house full of critters of my
            own at home who I love to pieces. I am a volunteer animal keeper
            with a local wildlife sanctuary and a wolf-dog rescue. I'm a bird
            nerd, bird watcher, I love traveling, hiking, exploring, and just
            being in nature. Adventures with my husband and our dogs are my
            absolute favorite thing
            <span role="img" aria-label="heart">
              ❤️
            </span>
          </p>
          <p className="bio-text">
            Thank you for being here and supporting my work! I love connecting
            with you guys and geeking out over animals and how wonderful they
            are! It makes me so happy to know my art has resonated with you.
          </p>
          {/* <p className="bio-text">
            Hey there! My name is Kendel and I'm a mixed media artist living in Reno, Nevada. 
            I create artwork inspired by things I find beautiful and interesting in nature. 
            I am passionate about shining light on creatures that are misunderstood like rats, mice, snakes, ravens, crows, pigeons, etc. 
            I have pet rats of my own that show up in my work often! My favorite mediums to work with are watercolor, ink, acrylic and digital. 
          </p>
          <p className="bio-text">
            In my free time you'll find me gardening, traveling, playing video games, taking care of my plants and animals, 
            and exploring the Reno/Tahoe area with my partner and our dog, Kai.
          </p> */}
        </div>
        <div className="col-md-4 col-sm-5 col-xs-12">
          <div className="thumbnail">
            {/* <div
              className="image pic1"
              style={{ backgroundImage: "url(" + bioImage2 + ")" }}
            /> */}
            <img
              src={bioImage2}
              alt="kendel and jupiter"
              className="image-bio"
            />
          </div>
        </div>
      </div>
    </About>
  );
}

export default BioPage;
