import React from 'react'
import GalleryPage from './GalleryPage'
import beloved_header from "../img/beloved/beloved_header.png";
import { Beloved } from '../ui/BelovedStyles';

const BelovedPage = () => (
  <>
    <Beloved className="container">
      <div className="row">

        <div className="col-xs-1"/>
        <div className="col-xs-10">
          <div className="thumbnail">
            <img src={beloved_header} alt="Beloved" />
          </div>
        </div>
        <div className="col-xs-1"/>

        <div className="col-xs-1"/>
        <div className="col-xs-10 description">
          For me, it's always been animals. From taking care of my own, birdwatching, animal keeping, the list goes on - 
          it's no surprise they are my muse. I've always been drawn to the ones that tend to have a bad rep, ever since I was little. 
          When I was around 10 years old I got my first pet rat, realized how wonderful they are, and that was the beginning.
          <br/>
          <br/>
          I've wanted to create a group of paintings that can change people's minds about animals they might be hesitant to like, 
          might be afraid of. They are all so beautiful and important in their own ways.
          <br/>
          <br/>
          This is for the ones that are misunderstood. I hope you can notice their beauty, too. <span role="img" aria-label="heart">❤️</span>
          <br/>
          <br/>
          For more information or to purchase, email lupineravenartworks@gmail.com
          <br/>
          <br/>
          @ The Hub (Riverside location) in Reno, Nevada
          <br/>
          This collection was on display from November 1st - 30th 2023
        </div>
        <div className="col-xs-1"/>

      </div>
    </Beloved>

    <div className="row">
      <div className="col-sm-1 col-xs-0"/>
      <div className="col-sm-10 col-xs-12">
        <div className="thumbnail">
          <GalleryPage media='beloved' />
        </div>
      </div>
      <div className="col-sm-1 col-xs-0"/>
    </div>
    
  </>
  )

export default BelovedPage;