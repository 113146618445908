import styled from "styled-components";

export const FaqText = styled.div`
  .faq-header {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .email {
      text-decoration: underline;
  }

  p {
    margin-bottom: 3px;
  }
`;
