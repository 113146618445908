import styled from "styled-components";

export const Beloved = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;

  .description {
    font-size: 20px;
    margin-left: 8%;
    margin-bottom: -10px;
  }

`;