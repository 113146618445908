import styled from "styled-components";

export const Portfolio = styled.div`
  .gallery-link {
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    background: inherit;
  }

  .gallery-link:hover {
    opacity: 0.7;
  }

  .gallery-link-label {
    text-align: center;
    font-weight: 500;
    color: #444444;
    font-size: 1.5em;
  }

  /* centered columns styles */
  .row-centered {
    text-align: center;
  }

  .col-centered {
    display: inline-block;
    float: none;
    /* reset the text-align */
    text-align: left;
    /* inline-block space fix */
    margin-right: -4px;
    text-align: center;
  }

  .image {
    // border: 2px solid #000000;
    // height: 0;
    padding-bottom: 75%;
    width: 97%;
    margin: 1.66%;
    background-size: cover;
    border-radius: 15px;
    background-position: center center;
    aspect-ratio: 1/1;
  }

  .rect-img-container {
    position: relative;
  }
  
  .rect-img-container::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  
  .rect-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
