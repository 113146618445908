import styled from "styled-components";

export const Gallery = styled.div`
  margin: 0 10%;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .grid-sizer,
  .grid-item {
    width: 33.333%;
  }

  @media (max-width: 992px) {
    .grid-sizer,
    .grid-item {
      width: 100%;
    }
  }

  @media (min-width: 993px) and (max-width: 1199px) {
    .grid-sizer,
    .grid-item {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .grid-sizer,
    .grid-item {
      width: 33.333%;
    }
  }

  .grid-item {
    float: left;
    padding: 5px;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }

  .grid-item img {
    display: block;
    max-width: 100%;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const LoadingWheel = styled.div`
  margin: 60px auto;
  width: 30px;
`;
