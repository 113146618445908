import styled from "styled-components";

export const Logo = styled.div`
  .logo-img {
    width: 100%;
    cursor: pointer;
  }

  .logo-header {
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    color: #a1a1a1;
  }

  .logo-separator {
    border-color: #a1a1a1;
    margin: 0;
  }

  text-align: center;
  border-bottom: none;
`;

export const NavBar = styled.nav`
  .navbar-nav > li > .nav-link {
    color: black;
    font-weight: 600;
  }

  .navbar-nav > li > .nav-link:hover {
    color: #AA99DC;
  }

  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    background-color: white;
  }

  .dropdown-menu > li > .dropdown-link {
    color: black;
    font-weight: 600;
  }

  .dropdown-menu > li > .dropdown-link:hover {
    color: #AA99DC;
  }

  .dropdown-menu {
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    text-align: center;
    border: 0;
    box-shadow: none;
  }

  .navbar-nav > li > link {
    padding-bottom: 5px;
  }

  .navbar-nav > .open > a:focus {
    background-color: white;
  }

  .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top;
  }

  .navbar-links {
    text-align: center;
  }

  background: inherit;
  border: none;
`;
