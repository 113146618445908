import React from "react";
import { Unknown, UnknownImage } from "../ui/UnknownStyles";

function UnknownPage() {
  return (
    <div key="404">
      <Unknown>
        Oops! We can't find that page, but here is some bonus Art!
      </Unknown>
      <UnknownImage>
        <img
          src="https://kendel-leslie-art.s3-us-west-1.amazonaws.com/bonus_art/bonus.jpg"
          alt="couldn't load that either"
        />
      </UnknownImage>
    </div>
  );
}

export default UnknownPage;
