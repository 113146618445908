import styled from "styled-components";

export const About = styled.div`
  h1 {
    font-size: 2em;
    text-align: center;
    background-color: #83C5BE;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .image {
    height: 0;
    padding-bottom: 75%;
    width: 97%;
    margin: 1.66%;
    background-size: cover;
    border-radius: 15px;
    background-position: center center;
  }

  .bio-text {
    font-weight: 500;
  }

  .image-bio {
    height: 100%;
    width: 100%;
    // margin-top: 50px;
    margin: 1.66%;
    background-size: cover;
    background-position: center center;
    border-radius: 15px;
  }

  .image-commission {
    height: 100%;
    width: 100%;
    // margin-top: 50px;
    margin: 1.66%;
    background-size: cover;
    background-position: center center;
    border-radius: 15px;
  }

  .image-shows {
    width: 80%;
    margin: 5px auto;
    text-align: center;
    display: block;
  }

  padding-top: 30px;
`;
