import React from "react";
import { About } from "../ui/CommonStyles";
// import { Form } from "../ui/ContactStyles";

function ContactPage() {
  // const [errors, setErrors] = useState({});

  // const [message, setMessage] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   subject: "",
  //   message: ""
  // });

  // let thankYou;

  // function handleChange({ target }) {
  //   const updatedMessage = {
  //     ...message,
  //     [target.name]: target.value
  //   };
  //   setMessage(updatedMessage);
  // }

  // function validate() {
  //   const _errors = {};

  //   if (!message.firstName) _errors.firstName = "First Name required";
  //   if (!message.lastName) _errors.lastName = "Last Name required";
  //   if (!message.email) _errors.email = "Email required";
  //   if (!message.subject) _errors.subject = "Subject required";
  //   if (!message.message) _errors.message = "Message required";

  //   setErrors(_errors);

  //   return Object.keys(_errors).length === 0;
  // }

  // function handleSubmit() {
  //   if (!validate()) return;

  //   let data_js = {
  //     access_token: "hj754xrf07574dsdy25inblq",
  //     subject: message.subject,
  //     text:
  //       message.firstName +
  //       " " +
  //       message.lastName +
  //       "\n" +
  //       message.email +
  //       "\n\n" +
  //       message.message
  //   };

  //   let request = new XMLHttpRequest();

  //   function toParams(data_js) {
  //     let form_data = [];
  //     for (let key in data_js) {
  //       form_data.push(
  //         encodeURIComponent(key) + "=" + encodeURIComponent(data_js[key])
  //       );
  //     }

  //     return form_data.join("&");
  //   }

  //   let params = toParams(data_js);

  //   request.open("POST", "https://postmail.invotes.com/send", true);
  //   request.setRequestHeader(
  //     "Content-type",
  //     "application/x-www-form-urlencoded"
  //   );

  //   request.send(params);

  //   setMessage({
  //     firstName: "",
  //     lastName: "",
  //     email: "",
  //     subject: "",
  //     message: ""
  //   });

  //   thankYou.className = "submission-text alert-success";
  // }

  return (
    <About className="container">
      <div className="row">
        <div className="col-md-4 col-sm-3 col-xs-0"/>
        <div className="col-md-4 col-sm-6 col-xs-12 text-center">
          <h1>contact</h1>
          <p>You can reach me at the email below or on Instagram</p>
          <p>LupineRavenArtworks@gmail.com</p>
          <p><a href="https://www.instagram.com/lupineravenartworks/" target="_blank" rel="noopener noreferrer">@lupineravenartworks</a></p>
        </div>
      </div>
    </About>
    // <Form className="container">
    //   <h1>Contact</h1>

    //   <fieldset>
    //     <div className="row">
    //       <div className="col-xs-2"></div>
    //       <div className="col-xs-8">
    //         <div className="row">
    //           <div className="title">
    //             Name <span className="required">*</span>
    //           </div>
    //           <input
    //             className="field col-xs-6 first-name"
    //             type="text"
    //             spellCheck="false"
    //             placeholder="First Name"
    //             name="firstName"
    //             onChange={handleChange}
    //             value={message.firstName}
    //           />

    //           <input
    //             className="field col-xs-6 last-name"
    //             type="text"
    //             spellCheck="false"
    //             placeholder="Last Name"
    //             name="lastName"
    //             onChange={handleChange}
    //             value={message.lastName}
    //           />
    //         </div>
    //         <div className="row">
    //           {errors.firstName && (
    //             <div className="first-name alert alert-danger col-xs-6">
    //               {errors.firstName}
    //             </div>
    //           )}
    //           {errors.lastName && (
    //             <div className="last-name alert alert-danger col-xs-6">
    //               {errors.lastName}
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //       <div className="col-xs-2"></div>
    //     </div>
    //   </fieldset>

    //   <div className="row">
    //     <div className="col-xs-2"></div>
    //     <div className="col-xs-8">
    //       <div className="row">
    //         <div className="title">
    //           Email Address <span className="required">*</span>
    //         </div>
    //         <input
    //           type="email"
    //           className="field"
    //           placeholder="Email"
    //           name="email"
    //           onChange={handleChange}
    //           value={message.email}
    //         ></input>
    //         {errors.email && (
    //           <div className="alert alert-danger">{errors.email}</div>
    //         )}
    //       </div>
    //     </div>
    //     <div className="col-xs-2"></div>
    //   </div>

    //   <div className="row">
    //     <div className="col-xs-2"></div>
    //     <div className="col-xs-8">
    //       <div className="row">
    //         <div className="title">
    //           Subject <span className="required">*</span>
    //         </div>
    //         <input
    //           type="text"
    //           className="field"
    //           placeholder="Subject"
    //           name="subject"
    //           onChange={handleChange}
    //           value={message.subject}
    //         ></input>
    //         {errors.subject && (
    //           <div className="alert alert-danger">{errors.subject}</div>
    //         )}
    //       </div>
    //     </div>
    //     <div className="col-xs-2"></div>
    //   </div>

    //   <div className="row">
    //     <div className="col-xs-2"></div>
    //     <div className="col-xs-8">
    //       <div className="row">
    //         <div className="title">
    //           Message <span className="required">*</span>
    //         </div>
    //         <textarea
    //           type="text"
    //           className="field"
    //           placeholder="Message"
    //           name="message"
    //           onChange={handleChange}
    //           value={message.message}
    //         ></textarea>
    //         {errors.message && (
    //           <div className="alert alert-danger">{errors.message}</div>
    //         )}
    //       </div>
    //     </div>
    //     <div className="col-xs-2"></div>
    //   </div>

    //   <div className="row">
    //     <div className="col-xs-2"></div>
    //     <div className="col-xs-8">
    //       <div className="row">
    //         <button
    //           type="submit"
    //           className="btn submit-btn"
    //           onClick={handleSubmit}
    //         >
    //           Submit
    //         </button>
    //       </div>
    //     </div>
    //     <div className="col-xs-2"></div>
    //   </div>
    //   <div className="row">
    //     <div className="col-xs-2"></div>
    //     <div className="col-xs-8">
    //       <div className="row">
    //         <div
    //           className="submission-text alert-success hidden"
    //           ref={thanks => (thankYou = thanks)}
    //         >
    //           Thank You!
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-xs-2"></div>
    //   </div>
    // </Form>
  );
}

export default ContactPage;
