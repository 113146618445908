import React from "react";
import { About } from "../ui/CommonStyles";
import { FaqText } from "../ui/FaqPageStyles";
//import { ImageUpcoming, ImagePast } from "../ui/ShowsPageStyles";
// import showsImage1 from "../img/about/ShowsImage1.jpg";
// import showsImage2 from "../img/about/ShowsImage2.jpg";
import showsImage3 from "../img/about/ShowsImage3.jpg";

function FaqPage() {
  return (
    <About className="container">
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <h1>faq</h1>
          <FaqText id="faq-text">
            <p>
              If you don't see your question here, feel free to ask me in an
              email!
            </p>
            <p className="email">lupineravenartworks@gmail.com</p>
            <p className="faq-header">Do you take commissions?</p>
            <p>Check my Instagram for updates on commissions. If you have any further questions feel free to reach out to the email above!</p>
            <p className="faq-header">Did you go to school for art?</p>
            <p>
              I did not! I have taken a few college level art classes, however I
              didn't go to school for art. Other than those, I am self taught!
            </p>
            <p className="faq-header">Can I get your work tattooed?</p>
            <p>
              As long as it is my original artwork and not a commission I did
              for someone else, I'm fine with that! Tag me or send me a picture,
              I'd love to see the final tattoo.
            </p>
            <p className="faq-header">
              What if I want a print of a piece or an original that's not in
              your shop?
            </p>
            <p>
              I don't list all of my pieces on my shop, so if you see something
              I post on social media or in person and you're interested in
              purchasing it, feel free to contact me and I'd be happy to get you
              pricing and more info! Same goes for prints. I make all of my
              prints at home so I can print one off pieces if you are
              interested.
            </p>
            <p className="faq-header">Where are you located?</p>
            <p>
              I live in Reno, Nevada! I participate in a lot of events and shows
              in the Reno/Tahoe area. I keep my Instagram up
              to date with events I'll be attending!
            </p>
          </FaqText>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          {/* {/* <img src={showsImage1} alt="" className="image-shows" /> */}
          {/* <img src={showsImage2} alt="" className="image-shows" />  */}
          <img src={showsImage3} alt="" className="image-shows" />
        </div>
      </div>
    </About>
  );
}

export default FaqPage;
