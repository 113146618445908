import React from "react";
import { About } from "../ui/CommonStyles";
import detailsImg from "../img/commission/details.png";
import faqImg from "../img/commission/faq.png";
import pricingImg from "../img/commission/pricing.png";
import statusImg from "../img/commission/status.png";
import ModalImage from "react-modal-image";

function CommissionPage() {
  return (
    <About className="container">
      <div className="row">
        <div className="col-md-4 col-sm-5 col-xs-12">
          <div className="thumbnail">
          <ModalImage
              className="image-commission"
              small={detailsImg}
              large={detailsImg}
              hideDownload={true}
              hideZoom={true}
              alt="details"
            />
          </div>
          <div className="thumbnail">
          <ModalImage
              className="image-commission"
              small={faqImg}
              large={faqImg}
              hideDownload={true}
              hideZoom={true}
              alt="faq"
            />
          </div>
        </div>
        <div className="col-md-4 col-sm-5 col-xs-12">
          <div className="thumbnail">
          <ModalImage
              className="image-commission"
              small={pricingImg}
              large={pricingImg}
              hideDownload={true}
              hideZoom={true}
              alt="pricing"
            />
          </div>
          <div className="thumbnail">
            <ModalImage
              className="image-commission"
              small={statusImg}
              large={statusImg}
              hideDownload={true}
              hideZoom={true}
              alt="commissions"
            />
          </div>
        </div>
        <div className="col-md-4 col-sm-7 col-xs-12">
          <h1>commissions</h1>
          <p className="bio-text">
            For commission requests please fill out this form: 
          </p>
          <p className="bio-text">
          <a
            href="https://forms.gle/bXzftnRXXjyh2YVB6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Commission Form
          </a>  
          </p>
          <p className="bio-text">
            For questions or business inquiries please email me at:
          </p>
          <p className="bio-text">
            lupineravenartworks@gmail.com
          </p>
        </div>
      </div>
    </About>
  );
}

export default CommissionPage;
