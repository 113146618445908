import React from "react";
import { Gallery, LoadingWheel } from "../ui/GalleryPageStyles";
import Masonry from "masonry-layout";
import Unknown from "./UnknownPage";
import { SphereSpinner } from "react-spinners-kit";

class GalleryPage extends React.Component {
  state = {
    unloadedImages: [],
    loadedImages: []
  };
  bottomImages = [];

  componentDidMount() {
    this.bottomImages = [];
    window.addEventListener("scroll", this.loadMoreImages, { passive: true });

    let media = this.props.match?.params.media;
    if(this.props.media) {
      media = this.props.media;
    }

    this.fillGallery(media).then(images => {
      this.setState({
        loadedImages: images.loadedImages,
        unloadedImages: images.unloadedImages
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMoreImages);
  }

  render() {
    return (
      <>
        <Gallery className="grid">
          <div className="grid-sizer" />
          <div>{this.state.loadedImages}</div>
        </Gallery>
        <LoadingWheel>
          <SphereSpinner
            size={30}
            color="#000000"
            loading={
              this.state.unloadedImages.length !== 0 ||
              this.state.loadedImages.length === 0
            }
          />
        </LoadingWheel>
      </>
    );
  }

  //fill the gallery
  fillGallery(media) {
    var promise = new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      var scope = this;
      request.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          var _images = JSON.parse(this.responseText);
          var unloadedImages = [];
          var loadedImages = [];
          this.bottomImages = [];

          var unknownPage = true;

          _images.forEach(function(image) {
            //make sure its an image file and not a folder, and its the proper media type
            if (image === media + "/") unknownPage = false;
            if (
              image !== undefined &&
              (image.endsWith(".jpg") || image.endsWith(".png")) &&
              image.startsWith(media + "/")
            ) {
              unloadedImages.push(
                "https://kendel-leslie-art.s3-us-west-1.amazonaws.com/" + image
              );
            }
          });

          for (var i = 0; i < 3; i++) {
            var imgToLoad = unloadedImages.shift();
            if (imgToLoad === undefined) break;
            loadedImages.push(scope.createImageHTML(imgToLoad));
          }

          if (unknownPage) loadedImages.push(Unknown());

          resolve({ loadedImages, unloadedImages });
        }
      };

      request.onerror = (event) => {
        setTimeout(this.getImages(request), 500);
      }

      this.getImages(request);
    });
    return promise;
  }

  getImages(request) {
    request.open(
      "GET",
      "https://m7m49cashl.execute-api.us-west-1.amazonaws.com/default/GetArtImage",
      true
    );
    request.send();
  }

  loadMoreImages = event => {
    var loadMore = false;
    this.bottomImages.forEach(
      img =>
        (loadMore =
          loadMore || img.getBoundingClientRect().bottom < window.innerHeight)
    );

    if (loadMore) {
      //make copies to use before updating them in state
      var unloadedImages = this.state.unloadedImages.slice(0);
      var loadedImages = this.state.loadedImages.slice(0);

      if (unloadedImages.length > 0) {
        var image = unloadedImages.shift();
        if (image !== undefined) {
          loadedImages.push(this.createImageHTML(image));
        }
        this.setState({
          loadedImages: loadedImages,
          unloadedImages: unloadedImages
        });
      }
    }
  };

  createImageHTML(image) {
    if (image === undefined) return;

    var htmlImage = (
      <div
        key={image}
        className="grid-item"
        ref={divElement => this.bottomImages.unshift(divElement)}
      >
        <img
          src={image}
          alt=""
          onLoad={() => {
            this.masonryPage();
            this.loadMoreImages();
          }}
        />
      </div>
    );

    this.bottomImages = this.bottomImages.slice(0, 2);
    return htmlImage;
  }

  masonryPage() {
    //don't do masonry if the images are just stacked normally
    // eslint-disable-next-line no-restricted-globals
    if (screen.width <= 991) return;

    // re-layout the grid
    new Masonry(".grid", {
      itemSelector: ".grid-item",
      percentPosition: true,
      columnWidth: ".grid-sizer",
      transitionDuration: 0
    }).layout();
  }
}

export default GalleryPage;
