import React from "react";
import { Socials } from "../../ui/FooterStyles";
import { NavBar } from "../../ui/HeaderStyles";

function Footer() {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
        integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ"
        crossOrigin="anonymous"
      />
      <NavBar className="navbar-default">
        <div className="container">
          <Socials>
            <ul className="nav navbar-nav">
              <li>
                <a
                  href="https://www.instagram.com/lupineravenartworks/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram fa-2x" />
                </a>
              </li>
              {/* <li>
                <a
                  href="https://www.facebook.com/KendelsArt/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f fa-2x" />
                </a>
              </li> */}
            </ul>
          </Socials>
        </div>
      </NavBar>
    </>
  );
}

export default Footer;
